import {useEffect, useState} from 'react'
import type {AppProps, NextWebVitalsMetric} from 'next/app'
import {ReactQueryDevtools} from 'react-query/devtools'
import {QueryClient, QueryClientProvider} from 'react-query'
import Layout from '@/components/layout'
import axios from 'axios'
import '../styles/globals.css'
import Script from 'next/script'
import {SmartLinkProvider} from '@/_new-code/services/kontent-ai/preview-mode/smart-link'
import TagManager from 'react-gtm-module'
import {PageProps} from '@/pages/[[...slug]]'
import {useMikMak} from '@/_new-code/services/mikmak'
import {AuthProvider} from '@/_new-code/products/auth/auth-provider'
// import {cspString} from '../csp.mjs'

export function reportWebVitals(metric: NextWebVitalsMetric) {
	const body = JSON.stringify(metric)
	const url = '/api/report/web-vitals'

	if (navigator.sendBeacon) {
		navigator.sendBeacon(url, body)
	} else {
		axios.post(url, {body, keepalive: true})
	}
}

const Application = ({
	Component,
	pageProps: rawPageProps,
	router,
}: AppProps<PageProps>): JSX.Element => {
	const pageProps = rawPageProps as PageProps
	if (router?.locale === 'null') return <p>404 - Not found</p>

	const mikMakAppId = pageProps.globalConfig?.elements.appId || null
	useMikMak(mikMakAppId)

	useEffect(() => {
		const trustBanner =
			pageProps.globalConfig.elements.trustArcBanner.trim()
		const country = pageProps.globalConfig.elements.country.trim()
		const language = pageProps.globalConfig.elements.language.trim()

		if (router?.locale === 'global') return () => {}
		const taScript = document.createElement('script')
		taScript.src = `https://consent.trustarc.com/notice?domain=${trustBanner}&c=teconsent&js=nj&noticeType=bb&text=true&country=${country}&language=${language}&gtm=1`
		document.head.appendChild(taScript)

		return () => taScript.remove()
	}, [router.locale])

	useEffect(() => {
		const gtmContainerID =
			pageProps.globalConfig.elements.gtmContainerId.trim()
		if (gtmContainerID && router?.locale !== 'global') {
			TagManager.initialize({
				gtmId: gtmContainerID,
			})
		}
	}, [])

	// Create a query client
	const [queryClient] = useState(() => new QueryClient())

	if (!pageProps.globalConfig) return <></>

	return (
		<>
			{/* This library is used for autocomplete functionality in the CDC Screenset */}
			<Script
				type="text/javascript"
				src="https://cdn.jsdelivr.net/npm/@tarekraafat/autocomplete.js@10.2.7/dist/autoComplete.min.js"
				async
			/>

			<SmartLinkProvider>
				<QueryClientProvider client={queryClient}>
					<AuthProvider {...pageProps}>
						<Layout {...pageProps}>
							<Component {...pageProps} />
						</Layout>
					</AuthProvider>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</SmartLinkProvider>
		</>
	)
}

export default Application
